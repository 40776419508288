/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import "styles/pages/competition.scss"

import React, { useState } from "react"
import axios from "axios"
import { graphql } from "gatsby"
import Slider from "react-slick"

import Layout from "components/layout"
import Seo from "components/seo"
import Radio from "components/Radio"

import { CompetitionQuestions } from "data/competition-2023"

const Competition = ({ data }) => {
  const title = "Competition"

  const today = new Date()

  const show1question = new Date("11/28/2023")
  const show2question = new Date("12/04/2023")
  const show3question = new Date("12/11/2023")
  const show4question = new Date("12/18/2023")
  const endCompetition = new Date(new Date("12/25/2023").getTime() + 3600000)

  const [modal, setModal] = useState(false)

  const [svar, setSvar] = useState(null)
  const [navn, setNavn] = useState("")
  const [teldubustadur, setTeldubustadur] = useState("")
  const [gota, setGota] = useState("")
  const [bygd, setBygd] = useState("")
  const [telefon, setTelefon] = useState("")
  const [error, setError] = useState(false)

  const resetForm = () => {
    setSvar(null)
    setNavn("")
    setTeldubustadur("")
    setGota("")
    setBygd("")
    setTelefon("")
  }

  const formSubmit = e => {
    e.preventDefault()

    setError(false)
    if (!!!svar) {
      setError(true)
      return
    }

    let formData = new FormData()
    formData.set("svar", svar)
    formData.set("navn", navn)
    formData.set("teldubustadur", teldubustadur)
    formData.set("gota", gota)
    formData.set("bygd", bygd)
    formData.set("telefon", telefon)

    axios({
      method: "post",
      url: `https://wp.mbm.fo/wp-json/contact-form-7/v1/contact-forms/${
        today > show1question && today <= show2question
          ? CompetitionQuestions[0]?.form
          : ""
      }${
        today > show2question && today <= show3question
          ? CompetitionQuestions[1]?.form
          : ""
      }${
        today > show3question && today <= show4question
          ? CompetitionQuestions[2]?.form
          : ""
      }${
        today > show4question && today <= endCompetition
          ? CompetitionQuestions[3]?.form
          : ""
      }/feedback/`,
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(() => {
        resetForm()
        setModal(true)
        console.log("Success")
      })
      .catch(() => {
        console.warn("Error")
      })
  }

  const slider = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 750,
    autoplay: true,
    autoplaySpeed: 4500,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    pauseOnHover: false,
  }

  const sliderData = [
    require("assets/images/competition-bg-image-1.jpg").default,
    require("assets/images/competition-bg-image-2.jpg").default,
  ]

  return (
    <Layout isContest>
      <Seo title={title} />
      <section className="competition">
        <Slider {...slider}>
          {sliderData?.map((item, index) => (
            <div key={index}>
              <div
                className="competition__slide"
                style={{ backgroundImage: `url('${item}')` }}
              ></div>
            </div>
          ))}
        </Slider>

        <div className="container-fluid">
          <div className="competition__desktop">
            <div className="row align-items-center">
              <div className="col-md-5">
                <img
                  className="img-fluid"
                  src={
                    today <= endCompetition
                      ? require("assets/images/competition-desktop-header.png")
                          .default
                      : require("assets/images/competition-desktop-header-end.png")
                          .default
                  }
                  alt=""
                />
              </div>
              <div className="col-md-6 offset-md-1">
                {today <= endCompetition && (
                  <h4>
                    Fyri at luttaka í kappingini mást tú skanna QR-kotuna á
                    mjólkarpakkanum.
                  </h4>
                )}
              </div>
            </div>
          </div>

          <div className="competition__mobile">
            <img
              className="img-fluid"
              src={
                require("assets/images/competition-mobile-header.png").default
              }
              alt=""
            />
            <div className="competition__mobile-top">
              <strong>
                hvønn sunnudag í advent (vinnarin verður kunngjørdur mánadag)
              </strong>
              <span>......................</span>
              <p>Les treytirnar niðanfyri!</p>
            </div>

            {today <= endCompetition && (
              <div className="competition__tile">
                <h3>Svara spurninginum og skriva navn og bústað.</h3>
                <h4>
                  {today > show1question &&
                    today <= show2question &&
                    CompetitionQuestions[0]?.question}
                  {today > show2question &&
                    today <= show3question &&
                    CompetitionQuestions[1]?.question}
                  {today > show3question &&
                    today <= show4question &&
                    CompetitionQuestions[2]?.question}
                  {today > show4question &&
                    today <= endCompetition &&
                    CompetitionQuestions[3]?.question}
                </h4>

                <form className="competition__form" onSubmit={formSubmit}>
                  {CompetitionQuestions[
                    today > show1question && today <= show2question
                      ? 0
                      : today > show2question && today <= show3question
                      ? 1
                      : today > show3question && today <= show4question
                      ? 2
                      : today > show4question && today <= endCompetition && 3
                  ]?.options?.map((item, index) => (
                    <Radio
                      key={index}
                      name="question"
                      value={svar}
                      onChange={() => setSvar(item)}
                    >
                      {item}
                    </Radio>
                  ))}

                  <input
                    type="text"
                    name="navn"
                    value={navn}
                    onChange={e => setNavn(e.target.value)}
                    placeholder="Navn*"
                    required
                  />
                  <input
                    type="text"
                    name="teldubustadur"
                    value={teldubustadur}
                    onChange={e => setTeldubustadur(e.target.value)}
                    placeholder="Teldubústaður*"
                    required
                  />
                  <input
                    type="text"
                    name="gota"
                    value={gota}
                    onChange={e => setGota(e.target.value)}
                    placeholder="Gøta*"
                    required
                  />
                  <input
                    type="text"
                    name="bygd"
                    value={bygd}
                    onChange={e => setBygd(e.target.value)}
                    placeholder="Bygd/Býur*"
                    required
                  />
                  <input
                    type="text"
                    name="telefon"
                    value={telefon}
                    onChange={e => setTelefon(e.target.value)}
                    placeholder="Telefon"
                  />
                  {!!error && (
                    <small className="competition__form__error">
                      Tú mást svara spurninginum við at seta merki við eitt svar
                    </small>
                  )}
                  <button type="submit">Send</button>
                  <small>Í samstarvi við Atlantic Airways</small>
                </form>
              </div>
            )}
          </div>

          <div className="row">
            <div className="col-md-5">
              <div className="competition__tile">
                <h4>Vinnarar:</h4>
                {/* {data.wpPage.acfCompetition.competitionWinners2023 && (
                  <span style={{ marginBottom: "20px", display: "block" }}>
                    Tit sum hava svarað øðrum spurningi, sum kom ov tíðliga út,
                    vóru eisini við í kappingini.
                  </span>
                )} */}
                <ol reversed>
                  {data.wpPage.acfCompetition.competitionWinners2023 &&
                    data.wpPage.acfCompetition.competitionWinners2023.map(
                      (item, index) => (
                        <li key={index}>{item.competitionWinner}</li>
                      )
                    )}
                </ol>
              </div>
            </div>

            <div className="col-md-6 offset-md-1">
              <div className="competition__tile">
                <h4>RØtt SVAR:</h4>

                <ol>
                  {today > show2question && (
                    <li>
                      1. {CompetitionQuestions[0]?.question}{" "}
                      <span>{CompetitionQuestions[0]?.answer}</span>
                    </li>
                  )}
                  {today > show3question && (
                    <li>
                      2. {CompetitionQuestions[1]?.question}{" "}
                      <span>{CompetitionQuestions[1]?.answer}</span>
                    </li>
                  )}
                  {today > show4question && (
                    <li>
                      3. {CompetitionQuestions[2]?.question}{" "}
                      <span>{CompetitionQuestions[2]?.answer}</span>
                    </li>
                  )}
                  {today > endCompetition && (
                    <li>
                      4. {CompetitionQuestions[3]?.question}{" "}
                      <span>{CompetitionQuestions[3]?.answer}</span>
                    </li>
                  )}
                </ol>
              </div>
            </div>
          </div>

          <div className="competition__mobile">
            <div className="competition__mobile-info">
              TREYTIR:
              <br />
              Vinningurin er ein flogferð fyri tvey vaksin og tvey børn (0-11
              ár) beinleiðis til New York ella Gran Canaria við Atlantic
              Airways. Uppihald er ikki við. Vinningurin kann ikki býtast um við
              reiðan pening, onnur ferðamál og skal brúkast í seinasta lagi í
              2025.
              <br />
              <br />
              Tá ið ferðaseðilarnir eru bílagdir, fylgja teir vanligu
              ferðaseðlatreytunum hjá Atlantic Airways, og skulu broytingar
              gerast eftir bílegging, rindar vinnarin broytingargjald og
              møguligan prísmun.
            </div>
          </div>
        </div>
      </section>

      {modal && (
        <>
          <div
            className="competition-modal__overlay"
            onClick={() => setModal(false)}
          ></div>
          <div className="competition-modal">
            <img
              src={
                require("assets/images/competition-modal-header.png").default
              }
              alt=""
            />
            <p>Títt svar er sent til Røsku</p>
          </div>
        </>
      )}
    </Layout>
  )
}

export default Competition

export const query = graphql`
  query {
    wpPage(id: { eq: "cG9zdDo4ODM=" }) {
      id
      acfCompetition {
        competitionWinners2023 {
          competitionWinner
        }
      }
    }
  }
`
