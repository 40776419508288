import "./styles.scss"

import React from "react"

const Checkbox = ({
  children,
  name,
  checked,
  onChange,
  required,
  className,
  error,
}) => {
  return (
    <div
      className={`radio${className ? ` ${className}` : ""}${
        error ? ` radio__error` : ""
      }`}
    >
      <label className="radio__label">
        <input
          type="radio"
          name={name}
          checked={checked}
          value={checked}
          onChange={onChange}
          required={required}
        />
        <span>{children}</span>
      </label>
    </div>
  )
}

export default Checkbox
